import Vue from 'vue';
import { request as axios } from '@/util/request';

const API = {
  banner: '/boss/operation/banner',
  sort: '/boss/operation/banner/sort',
  status: '/boss/operation/banner/status',
};
export enum NAVIGATION_TYPE {
  special = 1,
  ware = 2,
  limited_time = 3,
  tab = 4,
  outer_page = 5,
  none = 6,
  rich_scan = 7,
}

const TYPE_MAP: any = {
  [NAVIGATION_TYPE.special]: '特色专区',
  [NAVIGATION_TYPE.ware]: '商品详情页',
  [NAVIGATION_TYPE.limited_time]: '限时抢购页',
  [NAVIGATION_TYPE.rich_scan]: '扫一扫',
  [NAVIGATION_TYPE.tab]: '分类tab',
  [NAVIGATION_TYPE.outer_page]: '外部跳转',
  [NAVIGATION_TYPE.none]: '不跳转',
};
const STATUS_MAP: any = {
  1: '上架中',
  2: '下架中',
};

function formatBanner(remote: any = []): RemotePagination<BannerTableData> {
  return {
    current: remote.current || 1,
    limit: remote.limit || 10,
    detail: remote.detail || [],
    total: remote.total || 0,
    last: remote.last || 1,
  };
}

function formatBannerData(remote: any = {}) {
  return {
    id: remote.id,
    sort: remote.sort,
    banner_img_url: remote.banner_url,
    banner_name: remote.title,
    navigation_type: remote.type,
    navigation_type_text: getNavTypeText(remote.type),
    navigation_page: getNavigationPageText(remote),
    status: remote.status,
    status_text: STATUS_MAP[remote.status],
    update_time: remote.update_time,
    start_time: remote.start_time,
    end_time: remote.end_time,
    position: remote.position,
  } as BannerTableData;
}

function getNavigationPageText(remote: any) {
  switch (remote.type) {
    case NAVIGATION_TYPE.special:
      return `${TYPE_MAP[remote.type]}/${remote.remark_name}`;
    case NAVIGATION_TYPE.ware:
      return `${TYPE_MAP[remote.type]}/${remote.remark}`;
    case NAVIGATION_TYPE.limited_time:
    case NAVIGATION_TYPE.rich_scan:
    case NAVIGATION_TYPE.tab:
      return TYPE_MAP[remote.type];
    case NAVIGATION_TYPE.outer_page:
      return remote.remark;
    case NAVIGATION_TYPE.none:
      return '-';
    default:
      return '-';
  }
}

function getNavTypeText(type: number) {
  switch (type) {
    case NAVIGATION_TYPE.special:
    case NAVIGATION_TYPE.ware:
    case NAVIGATION_TYPE.limited_time:
    case NAVIGATION_TYPE.rich_scan:
    case NAVIGATION_TYPE.tab:
      return '内部跳转';
    case NAVIGATION_TYPE.outer_page:
      return '外部跳转';
    case NAVIGATION_TYPE.none:
      return '不跳转';
    default:
      return '';
  }
}

/**
 * 查询 banner 列表
 * @param current 当前页面
 */
async function queryBanner(
  current: number = 1,
  send_data?: {
    title?: string;
    status?: number;
    position?: number;
    start_time_sort?: any;
    end_time_sort?: any;
  }
) {
  const res = await axios.get(API.banner, { params: { current, ...send_data } });
  const banner = formatBanner(res.data);
  banner.detail = banner.detail.map((item: any) => formatBannerData(item));
  return banner;
}

/**
 * 获取单个 banner 数据
 * @param id banner id
 */
async function queryBannerById(id: number) {
  return await axios.get(`${API.banner}/${id}`);
}

/**
 * 添加 banner
 * @param banner_url banner 图
 * @param title banner 名称
 * @param type 跳转类型
 * @param remark 专区/商品 id | 跳转页面链接
 * @param remark_name 跳转页面名称
 */
async function addBanner(
  banner_url: string,
  title: string,
  type: number,
  remark: number | string,
  remark_name: string,
  bg_color: any,
  position: any,
  start_time?: any,
  end_time?: any
) {
  return await axios.post(API.banner, {
    banner_url,
    title,
    jump_type: type,
    type,
    remark,
    remark_name,
    bg_color,
    position,
    start_time,
    end_time,
  });
}

/**
 * 编辑轮播图
 * @param id banner id
 * @param banner_url 轮播图 url
 * @param title 轮播图名称
 * @param type 跳转类型
 * @param remark 专区/商品 id
 * @param remark_name 跳转页面名称
 */
async function updateBanner(
  id: number,
  banner_url: string,
  title: string,
  type: NAVIGATION_TYPE,
  remark: number | string,
  remark_name: string,
  bg_color: any,
  position: any,
  start_time?: any,
  end_time?: any
) {
  return await axios.put(`${API.banner}/${id}`, {
    banner_url,
    title,
    type,
    remark,
    remark_name,
    bg_color,
    jump_type: type,
    position,
    start_time,
    end_time,
  });
}

/**
 * 编辑 banner 的排序值
 * @param id banner id
 * @param sort 排序值
 */
async function updateSort(id: number, sort: number) {
  return await axios.put(`${API.sort}/${id}`, { sort });
}

/**
 * 编辑上下架
 * @param id banner id
 * @param status 上下架状态 1 上架中，2 下架中
 */
async function updateStatus(id: number, status: number) {
  return await axios.put(`${API.status}/${id}`, { status });
}

export default queryBanner;
export { queryBanner, queryBannerById, addBanner, updateBanner, updateSort, updateStatus };
