import { request as axios } from '@/util/request';
const clean = require('bmh/clean-empty-obj');

interface BaseSendData {
  title: string;
  subtitle: string;
  home_image_url: string;
  home_image_plus_url: string;
  detail_page_title: string;
  banner_image_url: string;
  colour_number: string;
  style: number;
}

interface AddSendData extends BaseSendData {
  box?: Module[];
}

interface UpdateSendData extends BaseSendData {
  add_box?: Module[];
  edit_box?: EditModule[];
  delete_box_id?: number[];
}

interface Module {
  id?: number;
  title: string;
  banner_image_url: string;
  product: Product[];
}

interface EditModule {
  id: number;
  title: string;
  banner_image_url: string;
  add_product: Product[];
  edit_product: { id: number; sort: number }[];
  delete_box_id: number[];
}

interface Product {
  product_id: number;
  sort: number;
}

const API = {
  zone: '/boss/operation/zone',
  sort: '/boss/operation/zone/sort',
  status: '/boss/operation/zone/status',
};

const STATUS_MAP: any = {
  1: '上架中',
  2: '下架中',
};

function formatZone(remote: any = {}): RemotePagination<SpecialZoneData> {
  return {
    current: remote.current || 1,
    limit: remote.limit || 10,
    detail: remote.detail || [],
    total: remote.total || 0,
    last: remote.last || 1,
  };
}

function formatZoneData(remote: any = []): SpecialZoneData {
  return {
    id: remote.id,
    sort: remote.sort,
    home_image_url: remote.home_image_url,
    home_image_plus_url: remote.home_image_plus_url,
    banner_image_url: remote.banner_image_url,
    title: remote.title,
    subtitle: remote.subtitle,
    detail_page_title: remote.detail_page_title,
    colour_number: remote.colour_number,
    box: remote.box,
    box_count: remote.box_count,
    status: remote.status,
    status_text: STATUS_MAP[remote.status],
    style: remote.style,
    update_time: remote.update_time,
  };
}

/**
 * 查询特色专区列表
 * @param current 当前分页
 * @param title 标题
 * @param limit 每页显示多少
 */
async function queryZone(
  current: number = 1,
  send_data?: { id?: number; title?: string; limit?: number }
) {
  clean(send_data);
  const res = await axios.get(API.zone, { params: { current, ...send_data } });
  const special_zone = formatZone(res.data);
  special_zone.detail = special_zone.detail.map((item: any) => formatZoneData(item));
  return special_zone;
}

/**
 * 查询专区详情数据
 * @param id 专区 id
 */
async function queryZoneById(id: number) {
  const res = await axios.get(`${API.zone}/${id}`);
  return formatZoneData(res.data);
}

/**
 * 新增专区
 * @param send_data
 */
async function addZone(send_data: AddSendData) {
  // clean(send_data);
  return await axios.post(API.zone, send_data);
}

/**
 * 编辑专区
 * @param id 专区 id
 * @param send_data
 */

async function updateZone(id: number, send_data: UpdateSendData) {
  return await axios.put(`${API.zone}/${id}`, send_data);
}

/**
 * 编辑专区排序
 * @param id 专区 id
 * @param sort 排序值
 */
async function updateZoneSort(id: number, sort: number) {
  return await axios.put(`${API.sort}/${id}`, { sort });
}

/**
 * 编辑上下架状态
 * @param id 专区 id
 * @param status 上/下架
 */
async function updateZoneStatus(id: number, status: number) {
  return await axios.put(`${API.status}/${id}`, { status });
}

export default queryZone;
export { queryZone, queryZoneById, addZone, updateZone, updateZoneStatus, updateZoneSort };
